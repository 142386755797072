var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workout-line text-left"},[_c('div',{staticClass:"description"},[(_vm.titleLine)?_c('div',{staticClass:"title-line"},[_vm._v("Description")]):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.workoutItem.description),expression:"workoutItem.description"}],class:{
        'non-editable': !_vm.editable,
        'exercise-finished': _vm.exerciseFinished,
      },attrs:{"type":"text","placeholder":"Description"},domProps:{"value":(_vm.workoutItem.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.workoutItem, "description", $event.target.value)}}},"input",_vm._d({},[_vm.readonly,_vm.readonly])))]),_c('div',{staticClass:"sets"},[(_vm.titleLine)?_c('div',{staticClass:"title-line"},[_vm._v("Sets")]):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.workoutItem.sets),expression:"workoutItem.sets"}],class:{
        'non-editable': !_vm.editable,
        'exercise-finished': _vm.exerciseFinished,
      },attrs:{"type":"number","placeholder":"Sets","min":"1"},domProps:{"value":(_vm.workoutItem.sets)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.workoutItem, "sets", $event.target.value)}}},"input",_vm._d({},[_vm.readonly,_vm.readonly])))]),_c('div',{staticClass:"reps"},[(_vm.titleLine)?_c('div',{staticClass:"title-line"},[_vm._v("Reps")]):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.workoutItem.reps),expression:"workoutItem.reps"}],class:{
        'non-editable': !_vm.editable,
        'exercise-finished': _vm.exerciseFinished,
      },attrs:{"type":"number","placeholder":"Reps","min":"1"},domProps:{"value":(_vm.workoutItem.reps)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.workoutItem, "reps", $event.target.value)}}},"input",_vm._d({},[_vm.readonly,_vm.readonly])))]),_c('div',{staticClass:"weight"},[(_vm.titleLine)?_c('div',{staticClass:"title-line"},[_vm._v("Weight")]):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.workoutItem.weight),expression:"workoutItem.weight"}],class:{
        'non-editable': !_vm.editable,
        'exercise-finished': _vm.exerciseFinished,
      },attrs:{"type":"number","placeholder":"Weight or Time","min":"0","step":"5"},domProps:{"value":(_vm.workoutItem.weight)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.workoutItem, "weight", $event.target.value)}}},"input",_vm._d({},[_vm.readonly,_vm.readonly])))]),_c('div',{staticClass:"done"},[(_vm.titleLine)?_c('div',{staticClass:"title-line"},[_vm._v("Done")]):_c('b-form-checkbox',_vm._b({class:{ 'non-editable': !_vm.editable },attrs:{"type":"check","value":"done","size":"lg"},model:{value:(_vm.workoutItem.done),callback:function ($$v) {_vm.$set(_vm.workoutItem, "done", $$v)},expression:"workoutItem.done"}},"b-form-checkbox",_vm._d({},[_vm.disabled,_vm.readonly])))],1),(_vm.editable)?_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"btn btn-outline-danger",on:{"click":function($event){return _vm.removeItem(_vm.workoutItem.id)}}},[_c('b-icon',{attrs:{"scale":"1.3","icon":"slash-circle"}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }