<template>
  <Users />
</template>

<script>
import Users from '../components/auth/Users.vue';

export default {
  name: 'WorkoutPage',
  components: {
    Users
  }
}
</script>